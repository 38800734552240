<template>
<div :id="`details-item-${ contrato.numeroContrato }`" class="modal modal-forms" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="title">
          <small>Financiamento</small>
          <h2 class>{{ contrato.descricao }}</h2>
        </div>
        <div class="number">
          <small>Número do Contrato</small>
          <p>{{ contrato.numeroContrato }}</p>
        </div>
        <!-- <h4 class="modal-title">Dados do contrato</h4> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>   
      <div class="modal-body">

        <div>
          <div v-if="carregando" class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            Carregando dados do contrato ... 
          </div>
          <div v-if="mensagemContratoEmAtraso">
            Olá, verificamos aqui que você possui parcela(s) em atraso. Para regularização, entre em contato com a nossa Central de Atendimento pelos telefones 4020-3300 ou 0800 646 7676 - Atendimento de segunda a sexta, das 09:00 às 18:00 hrs.
          </div>
          <div class="alert alert-primary" role="alert" v-if="sucessoEnvioEmai">
            E-mail enviado com sucesso.
          </div>
          <p class="alert alert-warning p-2" v-if="errors.length">
            <ul>
              <li
                v-for="(error, index) in errors"
                v-bind:key="index"
              >{{ error }}</li>
            </ul>
          </p>
          <div cl v-if="carregado && exibir">
            <div class="col-12 mb-4 fs-sm-down-08x px-0 px-md-3">
              <div class="justify-content-between d-lg-flex flex-row">
                <div class="vencimento mb-2 pb-3 fs-sm-down-09x">
                  <p class="mb-0">Vencimento <input ref="vencDatePicker" v-model="vencimento" type="text" style="visibility:hidden; width:1px;" > {{ vencimento | formatDate }}</p>
                  
                  <!-- <div ref="vencDatePicker" class="vencDatePicker">Aqui</div> -->
                  <div class="ml-4">
                    <a class="change-date" href="#" v-on:click="selecionaDataVenc">
                      <i class="far fa-calendar-alt"></i> Trocar data
                    </a>
                  </div>
                </div>
              </div>
              <hr>
              <table class="table table-hover table-left-right">
                <tbody>
                  <tr>
                    <td>Total de Parcelas:</td>
                    <td>{{ totalParcelas }}</td>
                  </tr>
                  <tr>
                    <td>Valor total das parcelas:</td>
                    <td>{{ valorTotalParcelas | formatCurrency }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fs-1hx font-weight-bolder">Total de desconto</span>
                    </td>
                    <td>
                      <span class="fs-1hx font-weight-bolder">- {{ simulacao.totalDesconto | formatCurrency}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="fs-1hx font-weight-bolder">Total a pagar</span>
                    </td>
                    <td>
                      <span class="fs-1hx font-weight-bolder">{{ simulacao.totalPagamento | formatCurrency}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr>
              
              <div class="row">
                
                <div class="col-12 col-lg-12 col-xl-12">
                  <div class="button-box">
                    <button
                      type="button"
                      class="btn btn-primary"
                      v-on:click="enviaEmailBoleto" v-if="apresentaAcao"
                    >
                      <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregandoEmail">
                        <span class="sr-only">Loading...</span>
                      </div>
                      Enviar boleto por e-mail
                    </button>
                    <button
                      type="button"
                      class="btn btn-link"
                      v-on:click="downloadBoleto" v-if="apresentaAcao"
                    >
                      <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregandoImprimir">
                        <span class="sr-only">Loading...</span>
                      </div>
                      Imprimir boleto
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <QuitarEnvioEmail ref="envioEmailModal" :contrato="contrato"></QuitarEnvioEmail>
        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script>
// import Contratos from "../api_portal_auto_atendimento/src/model/Contratos";
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";
import moment from "moment";
import QuitarEnvioEmail from '../components/QuitarEnvioEmail';

export default {
  name: "QuitarTabContent",
  props: {
    contrato: Object
  },
  components: {
    QuitarEnvioEmail
  },
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      simulacao:Object,
      processing: true,
      errors: [],
      contratoEmAtraso: false,
      buttonPressed: 0,
      vencimento:'',
      sucessoEnvioEmai:false,
      calendarioCriado:false,
      envioEmailModal:Object,
      exibir: true
    };
  },
  computed: {
    carregando: function() {
      return this.processing && this.buttonPressed == 0;
    },
    carregandoEmail: function() {
      return this.processing && this.buttonPressed == 1;
    },
    carregandoImprimir: function() {
      return this.processing && this.buttonPressed == 2;
    },
    carregado: function() {
      return (!this.processing || this.buttonPressed != 0) && !this.contratoEmAtraso;
    },
    mensagemContratoEmAtraso: function() {
      return this.contratoEmAtraso != '';
    },
    totalParcelas:function () {
      if (this.simulacao){
        if (this.simulacao.totalParcelas) {
          return this.simulacao.totalParcelas.length;
        }
      }
      return '';
    },
    valorTotalParcelas: function () {
      if (this.simulacao && this.simulacao.totalParcelas) {
        var total = 0;
        this.simulacao.totalParcelas.forEach(p => {
          total += p.valor;
        });
        return total;
      } else {
        return 0;
      }
    },
    apresentaAcao: function() {
      return !(this.errors.length > 0);        
    }
  },
  methods: {
    selecionaDataVenc: function (event) {
      if (event) {
        event.preventDefault();
      }
      let self = this;
      $(this.$refs.vencDatePicker).datepicker({
        daysOfWeekDisabled: [0,6],
        format: 'yyyy-mm-dd',
        startDate: new Date(),
        autoclose: true,
        language: "pt-BR",
      }).unbind('changeDate').on('changeDate',function(e) {
          self.setVencimento(moment(e.date).format("YYYY-MM-DD"));
      }).datepicker("show");
    },
    parserException(response, defaultMessage = 'Erro ao realizar operação.') {
      if (response && response.text) {
        try {
          let listOfErrors = JSON.parse(response.text);
          listOfErrors.forEach(error => {
            if (error.code && error.code == 901) {
              this.contratoEmAtraso = true;
            }

            this.errors.push(error.message);
          });
        } catch (e) {
          this.errors.push(defaultMessage);
        }
      } else {
        this.errors.push(defaultMessage);
      }
      if (this.errors.length > 0){
        this.simulacao = {};
      }
      if (this.contratoEmAtraso) {
        this.errors = [];
      }
    },
    loadDadosContrato() {
      this.errors = [];
      this.processing = true;
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();

      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let agencia = this.contrato.agencia;
      let contrato = this.contrato.numeroContrato; // String |
      let dataPagamento = moment(this.vencimento).format("YYYY-MM-DD");
      let situacao = "A"; // String |
      this.contratoEmAtraso = false;

      apiContrato.consultaQuitacaoContrato(empresa, agencia, contrato, dataPagamento, situacao, (error, data, response) => {
        this.processing = false;       
        // this.contratoEmAtraso = true;
        if (!error) {
          this.simulacao = data;
        } else {
          this.exibir = false;
          this.parserException(response, "Erro ao obter dados do contrato.");
        }
      });
    },
    downloadBoleto: function(event) {
      if (event) {
        event.preventDefault();
      }

      this.processing = true;
      this.buttonPressed = 2;

      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.timeout = 60000 * 2;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()
      let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();

      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let agencia = this.contrato.agencia;
      let contrato = this.contrato.numeroContrato; // String |
      let dataPagamento = moment(this.vencimento).format("YYYY-MM-DD");
      let situacao = "A"; // String |
      this.contratoEmAtraso = false;
      var self = this;

      apiContrato.consultaQuitacaoContratoSimulacao(empresa, agencia, contrato, dataPagamento, situacao, (error, data, response) => {
        if (!error) {
          apiContrato.boleto(empresa, data.simulacao, (error, data, response) => {
            self.processing = false;
            self.buttonPressed = 0;

            if (error) {
              this.parserException(response, 'Erro ao baixar boleto.');
            } else {
              let pdfBase64 = data.boletoPdf;
              this.download(pdfBase64, `boleto-${this.contrato.numeroContrato}.pdf`);
            }
          });
        } else {
          this.parserException(response, 'Erro ao baixar boleto.');
        }
      });
    },
    download(base64String, filename) {
      
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      var blob = new Blob([byteArray], { type: 'application/pdf' });
      if (window.navigator.msSaveBlob) { // // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
          window.navigator.msSaveOrOpenBlob(blob, filename);
      }
      else {
          var a = window.document.createElement("a");
          window.URL = window.webkitURL || window.URL;
          a.href = window.URL.createObjectURL(blob, { type: "application/pdf", oneTimeOnly: true  });
          a.download = filename;
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
          document.body.removeChild(a);
      }
    },
    enviaEmailBoleto() {
      if (event) {
        event.preventDefault();
      }

      this.$refs.envioEmailModal.show();

      // this.processing = true;
      // this.buttonPressed = 1;
    
      // let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      // defaultClient.timeout = 60000 * 2;
      // defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()
      // let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();

      // let userData = this.authService.userData();
      // let empresa = userData.Empresa;
      // let agencia = this.contrato.agencia;
      // let contrato = this.contrato.numeroContrato; // String |
      // let dataPagamento = moment(this.vencimento).format("YYYY-MM-DD");
      // let situacao = "A"; // String |
      // this.contratoEmAtraso = false;
      // var self = this;

      // apiContrato.consultaQuitacaoContratoSimulacao(empresa, agencia, contrato, dataPagamento, situacao, (error, data, response) => {
      //   if (!error) {
      //     apiContrato.emailBoleto(empresa, data.simulacao, (error, data, response) => {
      //       self.processing = false;
      //       self.buttonPressed = 0;

      //       if (error) {
      //         this.parserException(response, 'Erro ao baixar boleto.');
      //       } else {
      //         this.sucessoEnvioEmai = true;
      //         let self = this;
      //         setInterval(() => {
      //           self.sucessoEnvioEmai = false;
      //         }, 3000);
      //       }
      //     });
      //   } else {
      //     this.parserException(response, 'Erro ao baixar boleto.');
      //   }
      // });
    },
    getNextBusinessDay() {

      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()

      let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();

      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let agencia = this.contrato.agencia;

      apiContrato.proximoDiaUtil(empresa, agencia, this.vencimento, (error, data, response) => {
        if (error) {
          this.parserException(response, 'Erro ao obter próximo dia útil.');
        } else {
          this.vencimento = moment(data).format("YYYY-MM-DD");
          this.loadDadosContrato();
        }
      });
    },
    getCurrentDate() {

      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken();

      let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();

      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let agencia = this.contrato.agencia;

      apiContrato.diaUtil(empresa, agencia, this.vencimento, (error, data, response) => {
        if (error) {
          this.parserException(response, 'Erro ao validar vencimento.');
        } else {
          if(data) {
            this.loadDadosContrato();
          } else {
            this.getNextBusinessDay();
          }
        }
      });
    },
    setVencimento(newDate) {
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()

      let apiContrato = new ApiPortalAutoAtendimento.ContratoApi();

      let userData = this.authService.userData();
      let empresa = userData.Empresa;
      let agencia = this.contrato.agencia;

      this.errors = [];

      apiContrato.diaUtil(empresa, agencia, newDate, (error, data, response) => {
        if (error) {
          this.parserException(response, 'erro ao validar vencimento.');
        } else {
          if(data) {
            this.vencimento = newDate
            this.loadDadosContrato();
          } else {
            this.errors = [];
            this.errors.push('A data selecionada não é um dia útil.');
          }
        }
      });
    }
  },
  updated() {
    let self = this;
    $(this.$refs.vencDatePicker).datepicker({
      daysOfWeekDisabled: [0,6],
      format: 'yyyy-mm-dd',
      startDate: new Date(),
      autoclose: true,
      language: "pt-BR",
    }).unbind('changeDate').on('changeDate', function(e) {
        self.setVencimento(moment(e.date).format("YYYY-MM-DD"));
    });
  },
  mounted() {
    this.vencimento = moment().format("YYYY-MM-DD");
    this.getCurrentDate();
  }
};
</script>

<style scoped>

</style>